import { FtItinerary } from './ft-itinerary.model';

export class FtTransaction {
    transactionId: number;
    purchaseNumber: string;
    visaCode: string;
    merchantId: string;
    visaCreditCard: string;
    cipUrl: string;
    cipCode: string;
    oItinerary: FtItinerary;
    cipExpiry: string;
    redirect: any;
    total: any;
    insurance: any;
    pnr: string;
    alternativeFareBasis: boolean;
}
