import { VisaSession } from './visa-session.model';

export class VisaSessionRequest {
    sesionVisa: VisaSession;
    merchantId: string;
    sistema: string;
    codSistema: string;
    tabla: string;
    idtabla: string;
    nombre: string;
    apellido: string;
    tipodoc: string;
    numdoc: string;
    customerEmail: string;
    currency: string;
    productId: string;
    purchaseNumber: string;
}
