export class HotelFilter {
    static readonly STARS = 0;
    static readonly NAME = 1;
    static readonly PRICE = 2;
    static readonly KM = 3;
    static readonly KMPLACE = 4;

    order: number;
    value: string;
}
