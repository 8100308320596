import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as config from '../config';
import { Response } from '../../shared/models/response.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

let httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': environment.subsKey,
    
    })
};

@Injectable()
export class CharterService {
  
    private urlListCharters: string = environment.baseCharter + config.GET_CHARTER;
    private urlCharterDetail: string = environment.baseCharter + config.GET_CHARTER_DETAIL;
    private urlValidateStock: string = environment.baseCharter + config.VALIDATE_STOCK;
    private urlTransactionCharter: string = environment.baseCharter + config.GET_CHARTER_TRANSACTION;
    private urlConfirm: string = environment.baseCharter + config.CONFIRM_TRANSACTION;

    constructor(private http: HttpClient) {

    }

    getCharterTransaction(data): Observable<any> {
      // httpOptions.headers = new HttpHeaders({
      //   'Ocp-Apim-Subscription-Key': environment.subsKey,
      
      // });
      return this.http.post<any>(`${this.urlTransactionCharter}`, data, httpOptions);
  }

  getCharterConfirm(data): Observable<any> {
    // httpOptions.headers = new HttpHeaders({
    //   'Ocp-Apim-Subscription-Key': environment.subsKey,
      
    // });
    return this.http.post<any>(`${this.urlConfirm}`, data, httpOptions);
  }



  getValidateStock(data,data1): Observable<any> {
      // httpOptions.headers = new HttpHeaders({
      //   'Content-Type': 'application/json',
      //   'Ocp-Apim-Subscription-Key': environment.subsKey,
      
      // });
      const url = `${this.urlValidateStock}?${'charterPriceId=' + data}&${'numberPassenger=' + data1}`;
      return this.http.get<any>(url, httpOptions);
  }

    getCharters(): Observable<any> {
        // httpOptions.headers = new HttpHeaders({
        //   'Content-Type': 'application/json',
        //   'Ocp-Apim-Subscription-Key': environment.subsKey,
      
        // });
        return this.http.get<any>(`${this.urlListCharters}`, httpOptions);
    }

    getCharterDetail(data,data1): Observable<any> {
        // httpOptions.headers = new HttpHeaders({
        //   'Content-Type': 'application/json',
        //   'Ocp-Apim-Subscription-Key': environment.subsKey,
      
        // });
        const url = `${this.urlCharterDetail}?${'isAdministrator=' + data}&${'charterFlightId=' + data1}`;
        return this.http.get<any>(url, httpOptions);
    }

}