import { FtSection } from './ft-section.model';
import { FtPassenger } from './ft-passenger.model';
import { FtTax } from './ft-tax.model';
import { FtContact } from './ft-contact.model';
import { FtFlightOperation } from './ft-flight-operation.model';
import { FtSession } from './ft-session.model';
import { sectionAvailability } from '../flights/AvailabilitySection.model';

export class FtItinerary {
    numberPassengers: number;
    totalFareAmount: number;
    fareTaxAmountByPassenger: number;
    totalDiscount: number;
    percentageDiscount: number;
    gds: string;
    currency: string;
    PartnerClub: any;
    selected: any;
    carrierId: string;
    pseudo: string;
    flightNational: boolean;
    ocompany: any;
    ocarrier: any;
    ooffer: any | null;
    offerId: null | any;
    token: null | any;
    oprice: any;
    recommendationId: number;
    typeFlight: string;
    lsection: sectionAvailability[];
    lpassenger: any[];
    ltaxes: FtTax[];
    ocontactInfo: FtContact;
    oflightOperation: FtFlightOperation;
    osession: FtSession;
    transactionCode: number;
    passengers: any[] = [];
}