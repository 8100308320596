import { FtDocument } from './ft-document.model';

export class FtPassenger {
    passengerId: number;
    prefix: string;
    type: string;
    typePassenger: string;
    name: string;
    lastName: string;
    gender: string;
    birthDate: string;
    phoneNumber: string;
    phone: string;
    userId: string;
    isVIP: boolean;
    orole: any;
    email: string;
    frequentFlyer: string;
    residenceCountry: string;
    ocostCenter: any;
    odocument: FtDocument;
    personId: string;
}
