import { Passenger } from 'src/app/shared/models/passenger.model';

export class FlightBooking {
    adults: Passenger[];
    children: Passenger[];
    infants: Passenger[];
    contactName: string;
    contactLastName: string;
    contactDocumentType: string;
    contactDocument: string;
    contactMail: string;
    contactCountryCode: string;
    contactPhone: string;
    paymentType: string;
    checkTerms: string;
    paymentBank: string;
    mailDocument: string;
    fullName: string;
    typeVoucher: string;
    typeBuy: string;
    numberDocument: string;
    transactionId: number;
    purchaseNumber: string;
    visaCode: string;
    merchantId: string;
    cipUrl: string;
    cipCode: string;

}