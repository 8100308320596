export class Passenger {

    static readonly TYPE_ADULT = 'adult';
    static readonly TYPE_CHILD = 'child';
    static readonly TYPE_INFANT = 'infant';

    id: string;
    label: string;
    name: string;
    lastname: string;
    documentType: string;
    documentNumber: string;
    residenceCountry: string;
    dateBirth: string;
    gender: string;
    type: string;
    typePassenger: string;
    room: number;
    order: number;
    date: any;
    phone: string;
    mail: string;
}
