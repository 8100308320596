export const paymentInstructions = [
    {
      code: 'bcp',
      instructions: [
          {
              index: 1,
              // tslint:disable-next-line: max-line-length
              description: 'Selecciona la opción Pago de servicios > Empresas > <strong>PAGOEFECTIVO</strong> > <strong>PAGOEFECTIVO</strong> SOLES O DÓLARES.'
            },
            {
                index: 2,
                description: 'Ingresa tu código CIP (#CIP#) y sigue los pasos.'
            },
      ]
    },
    {
        code: 'interbank',
        instructions: [
            {
                index: 1,
                // tslint:disable-next-line: max-line-length
                description: 'Selecciona la opcion de Pago a Instituciones o Empresa > Empresa: <strong>PAGOEFECTIVO</strong> > Servicio: <strong>PAGOEFECTIVO</strong>.'
            },
            {
                index: 2,
                description: 'Ingresa tu código CIP (#CIP#) y sigue los pasos.'
            },
        ]
      },
      {
        code: 'bbva',
        instructions: [
            {
                index: 1,
                // tslint:disable-next-line: max-line-length
                description: 'Selecciona la opción Pago de Servicios > De Instituciones y Empresas > Busca por nombre <strong>PAGOEFECTIVO</strong> > <strong>PAGOEFECTIVO</strong> SOLES O DÓLARES.'
            },
            {
                index: 2,
                description: 'Ingresa tu código CIP (#CIP#) y sigue los pasos.'
            },
        ]
      },
      {
        code: 'scotiabank',
        instructions: [
            {
                index: 1,
                // tslint:disable-next-line: max-line-length
                description: 'Selecciona la opción Pagos > Otras Instituciones > Otros > Busca por Empresa/Servicio: <strong>PAGOEFECTIVO</strong> > Selecciona: <strong>PAGOEFECTIVO</strong> SOLES O DÓLARES.'
            },
            {
                index: 2,
                description: 'Ingresa tu código CIP (#CIP#) y sigue los pasos.'
            },
        ]
      },
      {
        code: 'banbif',
        instructions: [
            {
                index: 1,
                // tslint:disable-next-line: max-line-length
                description: 'Selecciona la opción Pago de servicios > Busca por Empresa y escribe <strong>PAGOEFECTIVO</strong> > Selecciona la empresa <strong>PAGOEFECTIVO</strong>.'
            },
            {
                index: 2,
                description: 'Ingresa tu código CIP (#CIP#) y sigue los pasos.'
            },
        ]
      },
      {
        code: 'western',
        instructions: [
            {
                index: 1,
                // tslint:disable-next-line: max-line-length
                description: 'Indica que vas a realizar un pago a la empresa <strong>PAGOEFECTIVO</strong>.'
            },
            {
                index: 2,
                description: 'Indica el Código (#CIP#) y el importe a pagar en soles o dólares.'
            },
        ]
      },
      {
        code: 'cajaarequipa',
        instructions: [
            {
                index: 1,
                // tslint:disable-next-line: max-line-length
                description: 'Selecciona la opción Pago de servicios > Busca la entidad <strong>PAGOEFECTIVO</strong> > Elige la moneda.'
            },
            {
                index: 2,
                description: 'Ingresa tu código (#CIP#) y sigue los pasos.'
            },
        ]
      },
      {
        code: 'moneygram',
        instructions: [
            {
                index: 1,
                // tslint:disable-next-line: max-line-length
                description: 'Indica que vas a realizar un pago a la empresa <strong>PAGOEFECTIVO</strong>.'
            },
            {
                index: 2,
                description: 'Indica el Código (#CIP#) y el importe a pagar en soles o dólares.'
            },
        ]
      }
  ];
