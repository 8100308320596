export class FtContact {
    contactName: string;
    contactLastName: string;
    contactEmail: string;
    contactPhone: string;
    contactDocumentType: string;
    contactDocumentNumber: string;
    contactCountryCode: string;
    mailDocument: string;
    fullName: string;
    titutarjeta: any;
    numtarjeta: any;
    cvv: any;
    fecexpi: any;
    typeBuy: string;
    typeVoucher: string;
    numberDocument: string;
}